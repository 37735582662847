@import 'owl-carousel/owl.carousel.less';
@import '_select2';
@import '_bootstrap-theme';
@import '_typography';
@import '_types';
@import '_main';
@import '_mobile_other';
@media (min-width:992px) and (max-width:1200px) {
  @import '_mobile_992-1200';
}
@media (min-width:768px) and (max-width:991px) {
  @import '_mobile_768-991';
}
@media (min-width:576px) and (max-width:767px) {
  @import '_mobile_576-767';
}
@media (max-width:575px) {
  @import '_mobile_575';
}



