@import '_variables';

#main-header{
    .main-navbar{
        .navbar-collapse{
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 0;
            background-color: @bg-color-header;
            
            .navbar-nav{
                padding: 10px 20px;
            }
            
            .nav-item{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        
        .account{
            display: block;
            
            .nav-item{
                display: block;
                float: left;
            }
        }
    }
}

#gas-price{
    padding: 50px 30px;
    
    h2{
        font-size: 30px;
    }
}

#properties{
    margin-top: 100px;
    
    .item{
        margin-bottom: 50px;
        padding-right: 0;
    }
}

#loyalty-program{
    font-size: 16px;
    
    p{
        line-height: 24px;
    }
}

h2, .h2{
    &.module{
        margin-bottom: 0;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
    }
}

#stock{
    margin-top: 100px;
    margin-bottom: 160px;
    
    figcaption{
        padding: 0 45px 0 35px;
        
        &[class*=first]{
            padding: 0 35px;
        }
    }
    
    h2{
        margin-bottom: 20px;
    }
    
    .btn{
        margin-top: 10px;
    }
}

#footer{
    .item-menu{
        margin-bottom: 45px;
    }
    
    .bottom-block{
        margin-top: 23px;
        margin-bottom: 0;
        border: none;
        
        span{
            display: block;
        }
    }
    
    .soc{
        margin-top: 35px;
        text-align: left;
        
        a{
            margin-right: 15px;
            margin-left: 0;
        }
    }
}

.page-service{
    .list-items{
        .item{
            margin-bottom: 20px;
            font-size: 16px;
            
            .title{
                bottom: 15px;
                left: 15px;
                padding-left: 10px;
                line-height: 18px;
            }
        }
    }
}

.page-fuel-cards{
    .list-coupons{
        .item{
            text-align: center;
        }
        
        img{
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.page-contact{
    .top-block{
        h2{
            margin-bottom: 15px;
        }
        
        ul{
            margin-bottom: 15px;
            
            li{
                margin-bottom: 5px;
            }
        }
    }
}

.page-stock{
    .list-items{
        .period{
            margin-bottom: 0;
        }
        
        h2{
            margin-bottom: 15px;
            font-size: 28px;
            line-height: 26px;
        }
        
        .btn-purple{
            margin-top: 0;
        }
    }
}

#top-image.club{
    figcaption{
        height: 226px;
        margin-top: -100px;
    }
    
    h1{
        font-size: 40px;
    }
}

.page-club{
    .how-it-work{
        .btn{
            display: block;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    
    .text{
        margin-top: -50px;
        margin-bottom: -25px;
        padding-top: 70px;
        padding-right: 25px;
        padding-left: 25px;
        
        ul{
            img{
                display: inline-block;
                margin-right: 10px;
            }
        }
        
        .bottom{
            margin: 0 -25px;
            padding: 25px;
        }
    }
    
    .brief{
        padding-top: 50px;
        padding-bottom: 70px;
    }
}

.form-login{
    .btn-purple{
        width: 100%;
        margin-bottom: 25px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
    }
}

#add-evaluation-thank{
    .has-bg{
        &::after{
            right: -125px;
        }
    }
}