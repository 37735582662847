@media only screen and (min-width: 991px) {
  .dropdown:hover .dropdown-menu {
    display:block;
    margin-top:0;
  }
}

@media (max-width: 991px) {
  #main-header {
    .main-navbar {
      .runner {
        display:none;
      }
      .dropdown-menu {
        position:static;
        padding:0 20px;
        li {
          display:block;
          padding:5px 0;
        }
      }
    }
  }

  .page-price, .page-service, .page-gross-fuel, .page-about, .page-article {
    margin-top:30px;
    margin-bottom:70px;
  }

  .page-gross-fuel .block-order {
    padding:0;
  }

  .page-fuel-cards {
    margin-bottom:70px;
    .coupons {
      padding-top:30px;
    }
  }

  .page-about {
    .item-block {
      height:auto;
      margin-bottom:25px;
    }
    .logo-block {
      display:none;
    }
    .video {
      bottom:30px;
    }
  }
}

@media (max-width: 767px) {
  .page-contact {
    .top-block {
      img {
        display:block;
        width:40%;
        margin:0 auto;
      }
    }
    .block-form {
      margin-top:40px;
      margin-bottom:20px;
    }
  }
  .page-stock {
    margin-top:30px;
    margin-bottom:60px;
    .list-items {
      .item {
        margin-bottom:50px;
      }
      .period {
        margin-top:30px;
      }
      .btn-purple {
        margin-top:0;
      }
    }
  }
  .page-club {
    .how-it-work {
      padding-top:30px;
      .btn {
        display:block;
        width:100%;
        margin-bottom:15px;
      }
    }
    .text {
      margin-top:20px;
      margin-bottom:0;
      padding-top:25px;
      padding-right:25px;
      padding-left:25px;

      ul {
        li {
          margin-bottom:20px;
        }
        img {
          display:inline-block;
          margin-right:5px;
          margin-bottom:5px;
        }
      }
      .bottom {
        margin:0 -25px;
        padding:25px;
      }
    }
    .brief {
      padding-top:50px;
      padding-bottom:70px;
    }
  }

  .page-login {
    margin-bottom:50px;
  }

  .item-register {
    .block-number {
      padding:15px 0;
    }
  }
  .section-coffee {
    padding:60px 0;
    .block {
      min-height:auto;
    }
    .image{
      img{
        margin:35px auto !important;
      }
    }
  }
}