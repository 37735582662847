@import '_variables';

#main-header{
    position: relative;
    padding: 23px 0 19px;
    color: @color-white;
    background-color: @bg-color-header;
    
    .language-menu{
        display: block;
        float: right;
        padding: 8px 21px 0;
        font-family: Ubuntu;
        font-size: 15px;
        line-height: 18px;
        
        li{
            display: block;
            
            &.active{
                color: @color-link-hover;
            }
        }
        
        a{
            color: inherit;
            
            &:hover, &:active{
                color: @color-link-hover;
            }
        }
    }
    
    .have-question{
        float: right;
        padding: 6px 27px 5px 19px;
        border-left: 1px solid #5e5e5e;
        text-align: right;
        
        span{
            display: block;
            font-family: Ubuntu;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
        }
        
        a{
            display: block;
            color: inherit;
            font-family: Ubuntu-Light;
            font-size: 14px;
            
            &:hover, &:active{
                color: @color-link-hover;
            }
        }
    }
    
    .call-us{
        float: right;
        padding-top: 6px;
        padding-bottom: 5px;
        padding-left: 20px;
        border-left: 1px solid @color-border-grey;
        text-align: right;
        
        a{
            display: block;
            color: inherit;
            font-family: Ubuntu-Light;
            font-size: 14px;
            
            &:hover, &:active{
                color: @color-link-hover;
            }
        }
        
        span{
            display: block;
            font-family: Ubuntu;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            
            svg{
                display: inline-block;
                margin-right: 9px;
                
                .a{
                    stroke-width: 0;
                }
            }
        }
    }
    
    .top-menu-container{
        position: relative;
        z-index: 20;
    }
    
    .main-navbar{
        position: absolute;
        top: 19px;
        right: 15px;
        left: 15px;
        padding: 25px 0 22px;
        border-bottom: 1px solid @color-white;
        color: @color-white;
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: bold;
        
        .navbar-nav{
            position: relative;
        }
        
        .nav-item{
            margin-right: 34px;
            
            &.active{
                .nav-link{
                    color: @color-link-hover;
                }
            }
        }
        
        .nav-link{
            padding: 0;
            color: inherit;
            
            &:hover, &:active{
                color: @color-link-hover;
            }
        }
        
        .account{
            position: static;
            font-size: 15px;
            font-weight: normal;
            
            .nav-item{
                margin-right: 0;
                padding: 0 16px;
                border-right: 1px solid @color-border-grey;
                
                &:last-of-type{
                    padding-right: 0;
                    border: none;
                }
            }
            
            .nav-link{
                svg{
                    display: inline-block;
                    margin-top: 4px;
                    margin-right: 6px;
                    vertical-align: top;
                }
                
                .a{
                    transition: @transition;
                    fill: @color-white;
                    stroke-width: 0;
                }
                
                &:hover, &:active{
                    color: @color-link-hover;
                    
                    .a{
                        fill: @color-link-hover;
                    }
                }
            }
        }
        
        .runner{
            position: absolute;
            bottom: -1px;
            border-top: 3px solid @color-link-hover;
            transition: all .3s ease-in-out .05s;
        }
        
        .navbar-toggler{
            padding-left: 0;
            border: none;
            border-radius: 0;
            outline: none;
            color: @color-white;
            font-size: 16px;
            font-weight: bold;
        }
        
        .navbar-toggler-icon{
            margin-right: 15px;
            background-image: url(img/icon-menu.svg);
        }
        
        .dropdown{
            position: static;
        }
        
        .dropdown-toggle{
            &::after{
                display: none;
            }
        }
        
        .dropdown-menu{
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            margin: 0;
            padding-top: 36px;
            border: none;
            border-radius: 0;
            background-color: transparent;
            font-size: 15px;
            
            li{
                display: inline-block;
                margin-right: 23px;
                
                &:last-of-type{
                    margin-right: 0;
                }
                
                &.active{
                    a{
                        color: @color-link-hover;
                    }
                }
            }
        }
        
        .dropdown-item{
            padding: 0;
            color: @color-white;
            background-color: transparent;
            
            &:hover, &:active{
                color: @color-link-hover;
            }
        }
    }
    
    .blackout{
        position: absolute;
        top: 100%;
        z-index: 5;
        width: 100%;
        height: 23vw;
        background-image: linear-gradient(to top, rgba(15, 35, 51, 0) 0%, rgba(15, 35, 51, .5) 40%, rgb(15, 35, 51) 100%);
    }
    
    &.empty{
        .main-navbar{
            position: relative;
        }
        
        .blackout{
            display: none;
        }
    }
}

#gas-price{
    position: relative;
    z-index: 5;
    margin-top: -4.8vw;
    padding: 50px 92px 38px;
    color: #ffffff;
    background-color: #1c1c1c;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .4);
    
    .block-select{
        margin-top: 25px;
        margin-bottom: 35px;
        
        .title{
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: bold;
        }
    }
    
    .text{
        margin-bottom: 50px;
    }
    
    .note{
        width: 50%;
        margin-top: -40px;
    }
    
    .table{
        margin-top: -15px;
        margin-bottom: 10px;
        color: @color-white;
        
        td{
            padding: 0 0 9px;
            font-size: 28px;
            vertical-align: top;
            
            &.name{
                padding-left: 33px;
                font-size: 26px;
                font-weight: bold;
                font-style: italic;
                vertical-align: bottom;
                text-transform: uppercase;
                
                .marking{
                    font-size: 16px;
                    text-transform: uppercase;
                    
                    &.is_pro{
                        color: @color-pro;
                    }
                }
                
                &.mark-new-line{
                    .marking{
                        display: block;
                        margin-top: -5px;
                        margin-left: 52px;
                        line-height: 15px;
                    }
                }
            }
            
            &.price{
                position: relative;
                left: 0;
                opacity: 1;
                transition: @transition;
                
                &.closed{
                    left: 80px;
                    opacity: 0;
                }
            }
        }
    }
}

#properties{
    margin-top: 150px;
    font-family: Ubuntu-Light;
    font-size: 14px;
    
    .item{
        display: block;
        padding-right: 55px;
        color: @color-text;
        
        svg{
            display: block;
            height: 47px;
            
            .a{
                transition: @transition;
                fill: @bg-color-icon;
                stroke-width: 0;
            }
        }
        
        h3{
            margin-top: 23px;
            margin-bottom: 15px;
            font-size: 22px;
            transition: @transition;
        }
        
        p{
            line-height: 21px;
        }
        
        .more{
            margin-top: 24px;
        }
        
        &:hover, &:active{
            .a{
                fill: @color-link-hover;
            }
            
            h3, .more{
                color: @color-link-hover;
            }
            
            .more::after{
                border-color: @color-link-hover;
            }
        }
    }
    
    .big-logo{
        margin-top: -102px;
        text-align: right;
    }
}

.more{
    display: block;
    color: inherit;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: bold;
    transition: @transition;
    
    &::after{
        display: inline-block;
        width: 30px;
        margin-left: 10px;
        border-bottom: 1px solid @color-text;
        vertical-align: middle;
        content: '';
    }
    
    &:hover, &:active{
        color: @color-link-hover;
        
        &::after{
            border-color: @color-link-hover;
        }
    }
}

#loyalty-program{
    position: relative;
    color: @color-white;
    font-family: Ubuntu-Light;
    font-size: 18px;
    
    .block-text{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        .container, .row{
            height: 100%;
        }
    }
    
    h2{
        margin-bottom: 30px;
        line-height: 37px;
    }
    
    p{
        line-height: 33px;
    }
    
    .btn-light{
        margin-top: 30px;
        color: @color-white;
    }
}

#stock{
    overflow: hidden;
    margin-top: 156px;
    margin-bottom: 186px;
    border-radius: 0 5px 0 5px;
    background-color: @color-white;
    box-shadow: 0 0 50px 0 rgba(170, 170, 170, .3);
    
    figcaption{
        padding: 0 100px 0 60px;
        
        &[class*=first]{
            padding: 0 70px;
        }
    }
    
    h2{
        margin-bottom: 30px;
    }
    
    .btn{
        margin-top: 17px;
    }
    
    .image{
        padding-right: 0;
        
        &[class*=last]{
            padding-right: 15px;
            padding-left: 0;
        }
    }
}

#footer{
    padding-top: 102px;
    color: @color-white;
    background-color: @bg-color-header;
    
    svg{
        max-width: 100%;
        
        .a{
            fill: #ffffff;
            stroke-width: 0;
        }
    }
    
    h3{
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 8px;
        text-transform: uppercase;
    }
    
    .nav{
        li{
            margin-bottom: 2px;
        }
        
        .active{
            a{
                color: @color-link-hover;
            }
        }
        
        a{
            display: inline-block;
            color: inherit;
            font-size: 14px;
            line-height: 14px;
            
            &:hover, &:active{
                color: @color-link-hover;
                text-decoration: none;
            }
        }
    }
    
    .drivers-menu{
        padding-left: 37px;
    }
    
    .partners-menu{
        padding-left: 55px;
    }
    
    .bottom-block{
        margin-top: 56px;
        padding-top: 48px;
        padding-bottom: 75px;
        border-top: 1px solid @color-border-grey;
        font-size: 14px;
    }
    
    .soc{
        text-align: right;
        
        a{
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-left: 15px;
            border-radius: 50rem;
            background-color: @color-white;
            text-align: center;
            
            svg{
                height: 100%;
            }
            
            .a{
                transition: @transition;
                fill: @color-text;
                stroke-width: 0;
            }
            
            &:hover, &:active{
                background-color: @color-link-hover;
                
                .a{
                    fill: @color-white;
                }
            }
        }
    }
}

#top-image{
    position: relative;
    
    img{
        width: 100%;
    }
    
    figcaption{
        position: absolute;
        bottom: 2vw;
        left: 0;
        z-index: 10;
        width: 100%;
    }
    
    h1{
        color: @color-white;
    }
    
    &.club{
        box-shadow: 0 0 50px 0 rgba(101, 101, 101, .4);
        
        figcaption{
            top: 50%;
            bottom: auto;
            height: 350px;
            margin-top: -175px;
        }
        
        h1{
            padding-left: 0;
            font-size: 56px;
            
            &::before{
                display: none;
            }
            
            span{
                display: block;
                color: @color-link-hover;
            }
        }
        
        .block-link{
            margin-top: 30px;
        }
        
        .btn{
            padding: 12px 30px;
        }
        
        .btn-purple{
            margin-right: 70px;
            color: @color-white;
            background-color: @color-border-btn;
            
            &::before{
                border-color: @color-white;
            }
            
            &:hover, &:active{
                border-color: @color-white;
                color: @color-black;
                background-color: @color-white;
                
                &::before{
                    border-color: @color-black;
                }
            }
        }
        
        .btn-light{
            &:hover, &:active{
                border-color: @color-white;
                color: @color-black;
                background-color: @color-white;
                
                &::before{
                    border-color: @color-black;
                }
            }
        }
    }
}

.fuel-types-text{
    margin-top: 71px;
    margin-bottom: 120px;
    
    .block-images{
        margin-top: 53px;
    }
    
    .item{
        margin-bottom: 30px;
        
        a{
            display: block;
            position: relative;
            box-shadow: none;
            transition: @transition;
            
            &::after{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                border-bottom: 6px solid @color-link-hover;
                content: '';
                transition: @transition;
            }
            
            &:hover, &:active{
                margin-top: -20px;
                margin-bottom: 20px;
                box-shadow: 0 15px 30px 0 rgba(22, 22, 22, .5);
                
                &::after{
                    width: 100%;
                }
            }
            
            img{
                width: 100%;
            }
        }
    }
}

.fuel-one-type-text{
    margin-top: 69px;
    margin-bottom: 200px;
    
    figure{
        margin-top: 40px;
    }
    
    .image{
        img{
            display: block;
            width: 100%;
            box-shadow: 0 0 30px 0 rgba(22, 22, 22, .4);
        }
    }
    
    figcaption{
        padding-left: 41px;
    }
}

#map{
    height: 700px;
    
    .gm-style{
        .gm-style-iw-c{
            padding: 12px !important;
            border-radius: 5px;
            color: @color-white;
            background-color: @color-text;
            box-shadow: .5px .866px 8px 0 rgba(22, 22, 22, .75);
            font-family: Ubuntu;
            text-align: center;
            
            .map-title{
                margin-bottom: 15px;
                padding-right: 30px;
                font-size: 15px;
                font-weight: bold;
            }
            
            .table-map{
                td{
                    padding: 5px 10px;
                }
            }
            
            button{
                top: 0 !important;
                right: 0 !important;
                background-image: url(img/map-close.svg) !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
                transition: @transition;
                
                img[src*='data:image/svg+xml']{
                    display: none !important;
                }
            }
        }
        
        .gm-style-iw-d{
            overflow: auto !important;
        }
        
        .gm-style-iw-t::after{
            background-color: @color-text;
            box-shadow: .5px .866px 8px 0 rgba(22, 22, 22, .75);
        }
    }
}

.page-price{
    margin-top: 90px;
    margin-bottom: 275px;
    
    #gas-price{
        margin-top: 0;
        
        h2{
            font-size: 20px;
        }
    }
}

.page-service{
    margin-top: 75px;
    margin-bottom: 115px;
    
    .list-items{
        margin-top: 18px;
        
        .item{
            display: block;
            position: relative;
            margin-bottom: 30px;
            padding: 0;
            font-size: 22px;
            font-weight: bold;
            
            img{
                display: block;
                width: 100%;
            }
            
            .title{
                position: absolute;
                right: 30px;
                bottom: 30px;
                left: 30px;
                padding-left: 20px;
                border-left: 2px solid @color-white;
                color: @color-white;
                
                img{
                    display: block;
                    max-width: 60px;
                    max-height: 55px;
                    margin-bottom: 10px;
                }
            }
            
            svg{
                display: block;
                margin-bottom: 10px;
            }
            
            .a{
                fill: @color-white;
                stroke-width: 0;
            }
        }
    }
}

.page-gross-fuel{
    margin-top: 74px;
    margin-bottom: 150px;
    
    h2{
        line-height: 32px;
        text-transform: uppercase;
    }
    
    .list-items{
        margin-top: 42px;
        
        .block{
            margin-bottom: 30px;
        }
        
        .item{
            height: 100%;
            padding: 25px 30px;
            border: 1px solid @color-border-form;
            
            &.certificate h3{
                background-image: url(img/icon-certificate.svg);
            }
            
            &.transport h3{
                background-image: url(img/icon-transport.svg);
            }
            
            &.sale h3{
                background-image: url(img/icon-sale.svg);
            }
            
            &.gps h3{
                background-image: url(img/icon-gps.svg);
            }
        }
        
        h3{
            margin-bottom: 0;
            padding-bottom: 20px;
            padding-left: 85px;
            background-repeat: no-repeat;
            background-position: left top;
        }
    }
    
    .block-order{
        margin-top: 10px;
        padding: 0 260px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        
        span{
            font-size: 30px;
        }
    }
}

.page-fuel-cards{
    margin-bottom: 155px;
    
    .coupons{
        padding-top: 75px;
        background-color: @color-white;
    }
    
    h2{
        margin-top: 0;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
    }
    
    ul{
        margin-bottom: 26px;
        
        li{
            position: relative;
            margin-bottom: 17px;
            padding-left: 50px;
            background-image: none;
            
            &::before{
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                width: 30px;
                border-bottom: 2px solid @color-black;
                content: '';
            }
        }
    }
    
    .image{
        img{
            display: block;
            width: 100%;
            margin-bottom: 25px;
            box-shadow: -9.235px 11.82px 35px 0 rgba(154, 154, 154, .5);
        }
    }
    
    .title{
        display: block;
        font-size: 18px;
        font-weight: bold;
    }
    
    .list-coupons{
        margin-bottom: 40px;
        font-size: 19px;
        
        .item{
            margin-bottom: 20px;
        }
        
        img{
            display: block;
            max-width: 100%;
            margin-bottom: 5px;
        }
    }
    
    .big-logo{
        position: relative;
        z-index: 2;
    }
    
    figure{
        position: relative;
        z-index: 3;
        margin-bottom: 35px;
    }
    
    .cards{
        margin-top: -170px;
        padding-top: 100px;
        background-color: @bg-color-body;
    }
    
    .list-cards{
        margin: 20px 0;
        
        .item{
            min-height: 55px;
            margin-bottom: 20px;
            padding-top: 5px;
            padding-left: 65px;
            background-repeat: no-repeat;
            background-position: left center;
            
            &.client{
                background-image: url(img/icon-client.svg);
            }
            
            &.gas{
                background-image: url(img/icon-gas.svg);
            }
            
            &.money{
                background-image: url(img/icon-money.svg);
            }
            
            &.cloud{
                background-image: url(img/icon-cloud.svg);
            }
        }
    }
}

.video{
    position: relative;
    box-shadow: -9.235px 11.82px 35px 0 rgba(154, 154, 154, .5);
    
    img.image{
        display: block;
        width: auto;
        min-width: 100%;
    }
    
    .play{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
        border-radius: 50rem;
        background-color: @color-border-btn;
        background-image: url(img/icon-play.svg);
        background-repeat: no-repeat;
        background-position: 37px center;
        box-shadow: 0 0 40px 0 rgba(63, 63, 63, .9);
        cursor: pointer;
    }
    
    .video-player{
        width: 100%;
    }
}

.page-about{
    margin-top: 80px;
    margin-bottom: 150px;
    
    h2{
        margin-top: 0;
        font-size: 30px;
        line-height: 45px;
        text-transform: uppercase;
    }
    
    .video{
        z-index: 10;
        margin-top: 43px;
    }
    
    .logo-block{
        position: relative;
        z-index: 5;
        margin-top: -95px;
        margin-bottom: 40px;
    }
    
    .item-block{
        height: 100%;
        padding: 30px;
        border: 1px solid @color-border-form;
        
        .title-block{
            margin-bottom: 20px;
            padding-left: 90px;
            background-repeat: no-repeat;
            background-position: 0 0;
            
            span{
                display: block;
                margin-bottom: 10px;
                font-weight: bold;
                line-height: 15px;
                text-transform: uppercase;
            }
            
            h3{
                font-size: 20px;
                font-weight: bold;
            }
            
            &.target{
                background-image: url(img/icon-target.svg);
            }
            
            &.philosophy{
                background-image: url(img/icon-philosophy.svg);
            }
            
            &.mission{
                background-image: url(img/icon-mission.svg);
            }
        }
    }
    
    .list-images{
        margin: 80px 0;
        
        .item-image{
            padding: 0;
        }
        
        img{
            min-width: 100%;
            height: 100%;
        }
        
        a{
            display: block;
            overflow: hidden;
            position: relative;
            padding-top: 1px;
            padding-right: 1px;
            
            &::before{
                position: absolute;
                top: 50%;
                right: 50%;
                bottom: 50%;
                left: 50%;
                border-radius: 50rem;
                background-color: transparent;
                content: '';
                transition: @transition;
            }
            
            &::after{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -27px;
                margin-left: -27px;
                opacity: 0;
                content: url(img/icon-plus.svg);
                transition: all .3s ease-in-out;
                transform: rotate(0);
            }
            
            &:hover, &:active{
                &::before{
                    top: 1px;
                    right: 1px;
                    bottom: 0;
                    left: 0;
                    border-radius: 0;
                    background-color: rgba(226, 35, 219, .7);
                }
                
                &::after{
                    opacity: 1;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

#slider-modal{
    .modal-content, .modal-body{
        padding: 0;
    }
    
    .image-block{
        img{
            width: 100%;
        }
    }
    
    .close{
        top: 0;
        right: 3px;
    }
}

.page-contact{
    h2{
        text-transform: uppercase;
    }
    
    .top-block{
        padding: 50px 0;
        color: @color-white;
        background-color: @color-border-form-hover;
        
        .list-info{
            margin-top: 30px;
        }
        
        h2{
            margin-bottom: 22px;
        }
        
        ul{
            margin-bottom: 50px;
            
            li{
                position: relative;
                margin-bottom: 17px;
                padding-left: 50px;
                background-image: none;
                
                &::before{
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 30px;
                    border-bottom: 2px solid @color-white;
                    content: '';
                }
            }
        }
        
        .text-block{
            span{
                display: block;
                font-size: 24px;
                font-weight: bold;
            }
            
            strong{
                font-size: 20px;
            }
        }
    }
    
    .block-form{
        margin-top: 95px;
        margin-bottom: 130px;
        
        h2{
            margin-bottom: 12px;
        }
        
        form{
            margin-top: 35px;
        }
        
        .icon{
            position: absolute;
            top: 16px;
            left: 30px;
            
            .a{
                transition: @transition;
                fill: @color-icon-form;
                stroke-width: 0;
            }
        }
        
        .form-group{
            margin-bottom: 30px;
        }
        
        .form-control{
            padding-right: 10px;
            padding-left: 45px;
            background-color: transparent;
            
            &:hover, &:active{
                + .icon .a{
                    fill: @color-border-form-hover;
                }
            }
        }
        
        textarea{
            + .icon{
                top: 20px;
            }
        }
        
        label{
            position: absolute;
            top: -9px;
            left: 30px;
            z-index: 1;
            margin: 0;
            padding: 0 5px;
            background-color: @bg-color-body;
            font-weight: normal;
        }
        
        .btn{
            width: 100%;
        }
    }
}

.page-stock{
    margin-top: 93px;
    margin-bottom: 170px;
    
    .list-items{
        .item{
            margin-bottom: 100px;
        }
        
        .image{
            img{
                display: block;
                width: 100%;
                box-shadow: -9.235px 11.82px 35px 0 rgba(154, 154, 154, .5);
            }
        }
        
        .period{
            margin-bottom: 17px;
            color: @color-time;
            
            time{
                color: inherit;
            }
        }
        
        h2{
            margin-bottom: 27px;
            font-size: 30px;
            line-height: 36px;
        }
        
        .btn-purple{
            margin-top: 25px;
        }
    }
}

.page-article{
    margin-top: 72px;
    margin-bottom: 160px;
    
    figure{
        margin-top: 42px;
        
        img{
            width: 100%;
        }
        
        h2{
            margin-top: 35px;
            margin-bottom: 25px;
            font-size: 20px;
            line-height: 1.2;
        }
    }
    
    ul{
        margin-bottom: 35px;
        
        li{
            position: relative;
            margin-bottom: 8px;
            padding-left: 30px;
            background-image: none;
            
            &::before{
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                width: 20px;
                border-bottom: 1px solid @color-black;
                content: '';
            }
        }
    }
}

.page-club{
    .how-it-work{
        h2{
            margin-bottom: 55px;
            font-size: 30px;
            line-height: 1.2;
            text-transform: uppercase;
        }
        
        ol{
            padding: 0;
            counter-reset: list;
            
            li{
                position: relative;
                margin-bottom: 45px;
                padding-left: 70px;
                list-style-type: none;
                
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: @color-border-btn;
                    font-size: 50px;
                    font-weight: bold;
                    line-height: 52px;
                    content: counter(list, decimal-leading-zero) '';
                    counter-increment: list;
                }
                
                .title{
                    display: block;
                    margin-bottom: 12px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        
        .block-link{
            margin-top: 75px;
        }
        
        .btn-black{
            margin-right: 70px;
        }
    }
    
    .text{
        margin-top: -100px;
        margin-bottom: -70px;
        padding: 95px 50px 0;
        color: @color-white;
        background-color: @color-border-form-hover;
        box-shadow: 0 0 30px 0 rgba(126, 126, 126, .5);
        
        .top-text{
            font-size: 18px;
        }
        
        ul{
            margin-top: 45px;
            margin-bottom: 35px;
            
            li{
                position: relative;
                margin-bottom: 40px;
                padding-left: 55px;
                background-image: none;
                
                &::before{
                    display: block;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 30px;
                    border-bottom: 1px solid @color-white;
                    content: '';
                }
            }
            
            img{
                display: inline-block;
                margin-right: 20px;
                
                &:last-of-type{
                    margin-right: 0;
                }
                
                & + p{
                    margin-top: 5px;
                    font-size: 16px;
                }
            }
            
            .percent{
                display: block;
                
                strong{
                    font-size: 25px;
                    font-weight: bold;
                }
            }
        }
        
        .bottom{
            margin: 0 -50px;
            padding: 50px;
            color: @color-text;
            background-color: @color-border-btn;
        }
    }
    
    .brief{
        padding-top: 135px;
        padding-bottom: 250px;
        color: @color-blockquote;
        background-color: @color-white;
        font-size: 14px;
        
        .block-text{
            padding-left: 90px;
            background-image: url(img/screamer.png);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
        
        h4{
            color: @color-text;
            font-size: 15px;
            font-weight: bold;
        }
        
        ol{
            margin: 0;
            padding-left: 15px;
            
            li{
                margin-bottom: 5px;
            }
        }
    }
}

.page-login{
    margin-bottom: 180px;
    
    h2{
        font-family: Ubuntu-Light;
        font-size: 25px;
        font-weight: normal;
    }
}

.page-header{
    margin: 57px 0;
    
    h1{
        &::before{
            border-color: @color-black;
        }
    }
}

.form-login{
    padding: 40px 40px 5px;
    background-color: @color-white;
    box-shadow: 0 0 30px 0 rgba(186, 186, 186, .35);
    
    label{
        margin-bottom: 10px;
    }
    
    .form-group{
        margin-bottom: 30px;
    }
    
    .btn-purple{
        padding-right: 85px;
        padding-left: 30px;
    }
}

.block-register-link{
    padding: 40px 40px 5px;
    
    .btn{
        margin-top: 30px;
    }
}

.page-error{
    padding-top: 140px;
    padding-bottom: 145px;
    color: @color-white;
    background-color: @bg-color-header;
    
    .title{
        font-weight: bold;
        text-transform: uppercase;
    }
    
    h1{
        margin-top: 12px;
        margin-bottom: 8px;
        font-size: 24px;
        text-transform: uppercase;
        
        &::before{
            display: none;
        }
    }
    
    .btn-purple{
        margin-top: 40px;
        color: @color-white;
        
        &::before{
            border-color: @color-white;
        }
    }
}

.item-register{
    margin-bottom: 40px;
    background-color: @color-white;
    box-shadow: 0 0 30px 0 rgba(186, 186, 186, .35);
    
    &.active{
        h1{
            color: @color-text;
        }
        
        .block-number{
            padding-top: 35px;
            background-color: @bg-color-header;
        }
        
        .block-data{
            padding: 35px 40px 35px 12px;
        }
        
        h2{
            margin-bottom: 12px;
        }
    }
    
    &.success{
        .block-number{
            background-color: @color-block-reg-success;
        }
        
        .block-data{
            background-image: url(img/icon-success.svg);
            background-repeat: no-repeat;
            background-position: 95% center;
        }
    }
    
    .block-number{
        height: 100%;
        padding-top: 17px;
        color: @color-white;
        background-color: @color-block-reg;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }
    
    .block-data{
        padding: 25px 40px 25px 12px;
    }
    
    h2{
        margin-bottom: 0;
        color: @color-block-reg;
        font-family: Ubuntu-Light;
        font-size: 25px;
        font-weight: normal;
        
        & + .title{
            margin-bottom: 22px;
        }
    }
    
    .form-control{
        background-color: transparent;
    }
    
    .btn{
        min-width: 220px;
        text-align: left;
    }
    
    .btn-black{
        &.btn-black{
            padding-top: 12px;
            padding-bottom: 12px;
            
            .back{
            }
            
            &.no-line{
                padding: 7px 20px;
                font-size: 15px;
                
                &:focus, &:active, &:hover{
                    border-color: @bg-color-header;
                    background-color: @bg-color-header;
                }
            }
        }
    }
}

.section-coffee{
    background-repeat: no-repeat;
    background-size: cover;
    color: @color-white;
    
    .block{
        min-height: 100vh;
    }
    
    .image{
        img{
            max-width: 100%;
            display: block;
        }
    }
    
    h2{
        font-family: Montserrat-ExtraBold;
        font-size: 30px;
        line-height: normal;
        text-transform: uppercase;
    }
    
    &.best-coffee{
        background-image: url(img/coffee-1.jpg);
        
        .image{
            img{
                margin-left: auto;
            }
        }
    }
    
    &.sort-coffee{
        background-image: url(img/coffee-2.jpg);
    }
    
    &.machine-coffee{
        background-image: url(img/coffee-3.jpg);
        
        .image{
            img{
                margin-left: auto;
            }
        }
    }
    
    &.glass-coffee{
        background-image: url(img/coffee-4.png);
    }
    
    &.water-coffee{
        background-image: url(img/coffee-5.jpg);
        color: @color-black;
        
        .image{
            img{
                margin-left: auto;
            }
        }
    }
    
    &.glass-syrup{
        background-image: url(img/coffee-6.jpg);
        color: @color-black;
    }
}

#add-evaluation-review{
    background-color: @bg-evaluation;
    min-height: 100vh;
    color: #ffffff;
    padding-bottom: 30px;
    overflow: hidden;
    
    .top-image{
        position: relative;
        
        & > img{
            width: 100%;
        }
        
        .logo{
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            text-align: center;
        }
        
        h1{
            position: absolute;
            font-size: 25px;
            left: 90px;
            bottom: 110px;
            width: 250px;
            
            &::before{
                display: none;
            }
        }
    }
    
    #evaluation-review-form{
        margin-top: -90px;
        
        .btn-submit{
            width: 100%;
            background-color: #dc1fd8;
            border-radius: 50rem;
            color: #ffffff;
            height: 60px;
            border: none;
            margin-top: 10px;
            position: relative;
            overflow: hidden;
            
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                border-radius: 50%;
                width: 50px;
                background-color: #ef34eb;
                transition: @transition;
            }
            
            &:hover{
                &::before{
                    left: -50px;
                }
            }
        }
    }
    
    .custom-radio{
        border-radius: 10px;
        background-color: @color-white;
        color: @color-text;
        padding: 20px 25px;
        margin-bottom: 20px;
        transition: @transition;
        box-shadow: 0 2px 10px 0 #e502e0;
        
        &:hover{
            background-color: #f4dcf3;
        }
    }
    
    input[type=radio]{
        display: none;
        
        & + label{
            position: relative;
            padding-left: 55px;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            line-height: 17px;
            cursor: pointer;
            min-height: 35px;
            display: flex;
            align-items: center;
            
            &::after{
                display: none;
            }
            
            &::before{
                position: absolute;
                left: 0;
                top: 0;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 2px solid @color-text;
                background-color: transparent;
                background-position: center;
                background-repeat: no-repeat;
                transition: @transition;
            }
        }
        
        &:checked{
            & + label{
                &::before{
                    background-image: url(img/check-evaluation.svg);
                    background-color: @color-border-evaluation;
                    border-color: @color-border-evaluation;
                }
            }
        }
    }
}

#add-evaluation-thank{
    background-color: @bg-evaluation;
    min-height: 100vh;
    color: #ffffff;
    overflow: hidden;
    
    .has-bg{
        position: relative;
        height: 100vh;
        padding-top: 115px;
        
        &::after{
            position: absolute;
            content: '';
            background-image: url(img/thank-bg.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 790px;
            height: 422px;
            bottom: 0;
            z-index: 1;
            right: 80px;
        }
    }
    
    h1{
        font-size: 25px;
        text-align: center;
        margin:0 auto 30px;
        width: 220px;
        position: relative;
        z-index: 5;
        
        &::before{
            display: none;
        }
    }
    
    .logo{
        position: relative;
        z-index: 5;
        
        img{
            display: block;
            margin: 0 auto;
        }
    }
}



















