@import '_variables';

#main-header{
    .cull-us{
        width: 50%;
        margin-top: 15px;
    }
    
    .have-question{
        width: 50%;
        margin-top: 15px;
        border: none;
    }
    
    .language-menu{
        position: absolute;
        right: 0;
        bottom: 100%;
        padding-bottom: 5px;
    }
    
    .main-navbar{
        position: static;
        margin-top: 10px;
        padding-top: 18px;
        padding-bottom: 0;
        border-top: 1px solid @color-white;
        border-bottom: none;
        
        .navbar-collapse{
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 0;
            background-color: @bg-color-header;
            
            .navbar-nav{
                padding: 10px 20px;
            }
            
            .nav-item{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        
        .account{
            display: block;
            width: 70%;
            
            .nav-item{
                display: block;
                float: left;
                max-width: 50%;
                font-size: 12px;
                line-height: 12px;
                text-align: right;
            }
            
            .nav-link{
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                
                svg{
                    margin-top: 0;
                }
            }
        }
        
        .navbar-toggler{
            width: 30%;
        }
        
        .navbar-toggler-icon{
            margin-right: 10px;
        }
    }
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev{
    z-index: 5;
}

#gas-price{
    margin: 0 -15px;
    padding: 50px 30px;
    
    h2{
        font-size: 30px;
    }
    
    img{
        display: none;
    }
    
    .note{
        display: block;
        width: auto;
        margin-top: 0;
    }
    
    p{
        display: none;
    }
}

#properties{
    margin-top: 80px;
    
    .item{
        margin-bottom: 50px;
        padding-right: 0;
    }
    
    .big-logo{
        display: none;
    }
}

#loyalty-program{
    font-size: 14px;
    
    .block-text{
        position: relative;
        padding: 30px 0;
        background-color: @bg-color-header;
    }
    
    h2{
        margin-bottom: 15px;
    }
    
    p{
        line-height: 20px;
    }
    
    .btn-light{
        margin-top: 15px;
    }
}

h2, .h2{
    &.module{
        margin-bottom: 0;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
    }
}

#stock{
    margin: 100px -15px 60px;
    
    figcaption{
        padding: 40px 45px 40px 35px;
        
        &[class*=first]{
            padding: 40px 35px;
        }
    }
    
    h2{
        margin-bottom: 20px;
    }
    
    .btn{
        margin-top: 10px;
    }
}

#footer{
    h3{
        line-height: normal;
    }
    
    .item-menu{
        margin-bottom: 45px;
    }
    
    .bottom-block{
        margin-top: 23px;
        margin-bottom: 0;
        border: none;
        
        span{
            display: block;
        }
    }
    
    .soc{
        margin-top: 35px;
        text-align: left;
        
        a{
            margin-right: 15px;
            margin-left: 0;
        }
    }
}

.fuel-one-type-text{
    margin-top: 50px;
    margin-bottom: 60px;
    
    .image{
        img{
            margin-bottom: 30px;
        }
    }
    
    figcaption{
        padding-left: 15px;
    }
}

.h1, h1{
    font-size: 32px;
}

.page-gross-fuel{
    .block-order{
        span{
            display: block;
        }
    }
}

.page-fuel-cards{
    .list-coupons{
        margin-bottom: 0;
        
        .item{
            text-align: center;
        }
        
        img{
            margin-right: auto;
            margin-left: auto;
        }
    }
    
    .big-logo{
        display: none;
    }
    
    .cards{
        margin-top: 0;
        padding-top: 45px;
    }
}

#top-image h1{
    font-size: 24px;
}

blockquote{
    padding: 90px 30px 30px;
    background-position: center 15px;
}

#top-image.club{
    figcaption{
        position: static;
        height: auto;
        margin-top: 20px;
    }
    
    h1{
        margin-bottom: 0;
        color: @color-text;
        font-size: 36px;
        line-height: 36px;
    }
    
    .block-link{
        margin-top: 15px;
        padding-right: 15px;
        
        .btn{
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
            
            &::before{
                border-color: @color-text;
            }
        }
        
        .btn-light{
            border-color: @color-border-btn;
            color: @color-text;
            background-color: @color-white;
        }
    }
}

.page-club{
    .text{
        margin: 20px -15px 0;
    }
    
    .brief{
        .block-text{
            padding-top: 90px;
            padding-left: 0;
            background-position: center top;
        }
    }
}

.form-login{
    .btn-purple{
        width: 100%;
        margin-bottom: 25px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
    }
}

.page-error{
    padding-top: 40px;
    padding-bottom: 45px;
    text-align: center;
}

#add-evaluation-review{
    #top-block{
        margin: 0 -70px;
    }
    
    .top-image{
        h1{
            width: 250px;
            left: 50%;
            margin-left: -125px;
        }
    }
}
#add-evaluation-thank{
    .has-bg{
        &::after{
            right: -300px;
        }
    }
}
