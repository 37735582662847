@color_1:#ffffff;
@color_2:#999999;
@color_3:#333333;
@color_4:#ffffff;
@color_5:#888888;
@color_6:#555555;
@color_7:grey;
@background_color_1:#1c1c1c;
@background_color_2:#ffffff;
@background_color_3:#eeeeee;
@background_color_4:#e4e4e4;
@background_color_5:#dddddd;
@background_color_6:#e502e0;
@background_color_7:#f7f7f7;
@background_color_8:#3875d7;
@border_color_1:#888888 transparent transparent transparent;
@border_color_2:transparent transparent #888888 transparent;
@border_color_3:#5897fb;

.select2-container {
  display:inline-block;
  position:relative;
  box-sizing:border-box;
  margin:0;
  vertical-align:middle;
  .select2-selection--single {
    display:block;
    box-sizing:border-box;
    cursor:pointer;
    -webkit-user-select:none;
            user-select:none;
    .select2-selection__rendered {
      display:block;
      overflow:hidden;
      padding:13px 45px 13px 29px;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
    .select2-selection__clear {
      position:relative;
    }
  }
  .select2-selection--multiple {
    display:block;
    box-sizing:border-box;
    min-height:32px;
    cursor:pointer;
    -webkit-user-select:none;
            user-select:none;
    .select2-selection__rendered {
      display:inline-block;
      overflow:hidden;
      padding-left:8px;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
  }
  .select2-search--inline {
    float:left;
    .select2-search__field {
      box-sizing:border-box;
      margin-top:5px;
      padding:0;
      border:none;
      font-size:100%;
      &::-webkit-search-cancel-button {
        -webkit-appearance:none;
      }
    }
  }
}
.select2-container[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__rendered {
      padding-right:8px;
      padding-left:20px;
    }
  }
}
.select2-dropdown {
  display:block;
  position:absolute;
  left:-100000px;
  z-index:1051;
  box-sizing:border-box;
  width:100%;
  border:2px solid #ffffff;
  background-color:@background_color_1;
}
.select2-results {
  display:block;
}
.select2-results__options {
  margin:0;
  padding:0;
  list-style:none;
}
.select2-results__option {
  padding:10px 28px;
  color:@color_1;
  -webkit-user-select:none;
          user-select:none;
}
.select2-results__option[aria-selected] {
  cursor:pointer;
}
.select2-container--open {
  .select2-dropdown {
    left:0;
  }
  .select2-dropdown--above {
    border-bottom:none;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
  .select2-dropdown--below {
    border-top:none;
    border-top-left-radius:0;
    border-top-right-radius:0;
  }
}
.select2-search--dropdown {
  display:block;
  padding:4px;
  .select2-search__field {
    box-sizing:border-box;
    width:100%;
    padding:4px;
    &::-webkit-search-cancel-button {
      -webkit-appearance:none;
    }
  }
}
.select2-search--dropdown.select2-search--hide {
  display:none;
}
.select2-close-mask {
  display:block;
  position:fixed;
  top:0;
  left:0;
  z-index:99;
  width:auto;
  min-width:100%;
  height:auto;
  min-height:100%;
  margin:0;
  padding:0;
  border:0;
  opacity:0;
  background-color:@background_color_2;

  filter:alpha(opacity=0);
}
.select2-hidden-accessible {
  overflow:hidden !important;
  clip:rect(0 0 0 0) !important;
  position:absolute !important;
  width:1px !important;
  height:1px !important;
  padding:0 !important;
  border:0 !important;
  white-space:nowrap !important;

  -webkit-clip-path:inset(50%) !important;
          clip-path:inset(50%) !important;
}
.select2-container--default {
  .select2-selection--single {
    border:2px solid #ffffff;
    background-color:transparent;
    font-size:15px;
    .select2-selection__rendered {
      color:@color_1;
    }
    .select2-selection__clear {
      float:right;
      font-weight:bold;
      cursor:pointer;
    }
    .select2-selection__placeholder {
      color:@color_2;
    }
    .select2-selection__arrow {
      position:absolute;
      top:0;
      right:2px;
      width:45px;
      height:100%;
      b {
        display:block;
        width:100%;
        height:100%;
        background-image:url(img/icon-select.svg);
        background-repeat:no-repeat;
        background-position:center;
        transition:.3s;
        transform:rotate(0);
      }
    }
  }
  .select2-selection--multiple {
    border:1px solid #aaaaaa;
    border-radius:4px;
    background-color:@background_color_1;
    cursor:text;
    .select2-selection__rendered {
      box-sizing:border-box;
      width:100%;
      margin:0;
      padding:0 5px;
      list-style:none;
      li {
        list-style:none;
      }
    }
    .select2-selection__placeholder {
      float:left;
      margin-top:5px;
      color:@color_2;
    }
    .select2-selection__clear {
      float:right;
      margin-top:5px;
      margin-right:10px;
      font-weight:bold;
      cursor:pointer;
    }
    .select2-selection__choice {
      float:left;
      margin-top:5px;
      margin-right:5px;
      padding:0 5px;
      border:1px solid #aaaaaa;
      border-radius:4px;
      background-color:@background_color_4;
      cursor:default;
    }
    .select2-selection__choice__remove {
      display:inline-block;
      margin-right:2px;
      color:@color_2;
      font-weight:bold;
      cursor:pointer;
      &:hover {
        color:@color_3;
      }
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      border:1px solid #aaaaaa;
    }
  }
  .select2-search--inline {
    .select2-search__field {
      border:none;
      outline:0;
      background:transparent;
      box-shadow:none;

      -webkit-appearance:textfield;
    }
  }
  .select2-results {
    > .select2-results__options {
      overflow-y:auto;
      max-height:200px;
    }
  }
  .select2-results__option[role=group] {
    padding:0;
  }
  .select2-results__option[aria-disabled=true] {
    color:@color_2;
  }
  .select2-results__option[aria-selected=true] {
    color:@background_color_6;
  }
  .select2-results__option {
    .select2-results__option {
      padding-left:1em;
      .select2-results__group {
        padding-left:0;
      }
      .select2-results__option {
        margin-left:-1em;
        padding-left:2em;
        .select2-results__option {
          margin-left:-2em;
          padding-left:3em;
          .select2-results__option {
            margin-left:-3em;
            padding-left:4em;
            .select2-results__option {
              margin-left:-4em;
              padding-left:5em;
              .select2-results__option {
                margin-left:-5em;
                padding-left:6em;
              }
            }
          }
        }
      }
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    color:@color_4;
    background-color:@background_color_6;
  }
  .select2-results__group {
    display:block;
    padding:6px;
    cursor:default;
  }
}
.select2-container--default[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__clear {
      float:left;
    }
    .select2-selection__arrow {
      right:auto;
      left:1px;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      float:right;
      margin-right:auto;
      margin-left:5px;
    }
    .select2-selection__placeholder {
      float:right;
    }
    .select2-search--inline {
      float:right;
    }
    .select2-selection__choice__remove {
      margin-right:auto;
      margin-left:2px;
    }
  }
}
.select2-container--default.select2-container--disabled {
  .select2-selection--single {
    background-color:@background_color_3;
    cursor:default;
    .select2-selection__clear {
      display:none;
    }
  }
  .select2-selection--multiple {
    background-color:@background_color_3;
    cursor:default;
  }
  .select2-selection__choice__remove {
    display:none;
  }
}
.select2-container--default.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        transform:rotate(180deg);
      }
    }
  }
}
.select2-container--default.select2-container--focus {
  .select2-selection--multiple {
    border:solid black 1px;
    outline:0;
  }
}
.select2-container--default.select2-container--open.select2-container--above {
  .select2-selection--single {
    border-top-left-radius:0;
    border-top-right-radius:0;
  }
  .select2-selection--multiple {
    border-top-left-radius:0;
    border-top-right-radius:0;
  }
}
.select2-container--default.select2-container--open.select2-container--below {
  .select2-selection--single {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
  .select2-selection--multiple {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
}
.select2-container--classic {
  .select2-selection--single {
    border:1px solid #aaaaaa;
    border-radius:4px;
    outline:0;
    background-color:@background_color_7;
    background-image:-webkit-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image:     -o-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image:        linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat:repeat-x;

    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    &:focus {
      border:1px solid #5897fb;
    }
    .select2-selection__rendered {
      color:@color_1;
      line-height:28px;
    }
    .select2-selection__clear {
      float:right;
      margin-right:10px;
      font-weight:bold;
      cursor:pointer;
    }
    .select2-selection__placeholder {
      color:@color_2;
    }
    .select2-selection__arrow {
      position:absolute;
      top:1px;
      right:1px;
      width:20px;
      height:26px;
      border:none;
      border-left:1px solid #aaaaaa;
      border-top-right-radius:4px;
      border-bottom-right-radius:4px;
      background-color:@background_color_5;
      background-image:-webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image:     -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image:        linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
      background-repeat:repeat-x;

      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
      b {
        position:absolute;
        top:50%;
        left:50%;
        width:0;
        height:0;
        margin-top:-2px;
        margin-left:-4px;
        border-width:5px 4px 0 4px;
        border-style:solid;
        border-color:@border_color_1;
      }
    }
  }
  .select2-selection--multiple {
    border:1px solid #aaaaaa;
    border-radius:4px;
    outline:0;
    background-color:@background_color_1;
    cursor:text;
    &:focus {
      border:1px solid #5897fb;
    }
    .select2-selection__rendered {
      margin:0;
      padding:0 5px;
      list-style:none;
    }
    .select2-selection__clear {
      display:none;
    }
    .select2-selection__choice {
      float:left;
      margin-top:5px;
      margin-right:5px;
      padding:0 5px;
      border:1px solid #aaaaaa;
      border-radius:4px;
      background-color:@background_color_4;
      cursor:default;
    }
    .select2-selection__choice__remove {
      display:inline-block;
      margin-right:2px;
      color:@color_5;
      font-weight:bold;
      cursor:pointer;
      &:hover {
        color:@color_6;
      }
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      border:1px solid #aaaaaa;
      outline:0;
    }
  }
  .select2-search--inline {
    .select2-search__field {
      outline:0;
      box-shadow:none;
    }
  }
  .select2-dropdown {
    border:1px solid transparent;
    background-color:@background_color_1;
  }
  .select2-dropdown--above {
    border-bottom:none;
  }
  .select2-dropdown--below {
    border-top:none;
  }
  .select2-results {
    > .select2-results__options {
      overflow-y:auto;
      max-height:200px;
    }
  }
  .select2-results__option[role=group] {
    padding:0;
  }
  .select2-results__option[aria-disabled=true] {
    color:@color_7;
  }
  .select2-results__option--highlighted[aria-selected] {
    color:@color_4;
    background-color:@background_color_8;
  }
  .select2-results__group {
    display:block;
    padding:6px;
    cursor:default;
  }
}
.select2-container--classic[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__clear {
      float:left;
    }
    .select2-selection__arrow {
      right:auto;
      left:1px;
      border:none;
      border-right:1px solid #aaaaaa;
      border-radius:0;
      border-top-left-radius:4px;
      border-bottom-left-radius:4px;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      float:right;
      margin-right:auto;
      margin-left:5px;
    }
    .select2-selection__choice__remove {
      margin-right:auto;
      margin-left:2px;
    }
  }
}
.select2-container--classic.select2-container--open {
  .select2-selection--single {
    border:1px solid #5897fb;
    .select2-selection__arrow {
      border:none;
      background:transparent;
      b {
        border-width:0 4px 5px 4px;
        border-color:@border_color_2;
      }
    }
  }
  .select2-selection--multiple {
    border:1px solid #5897fb;
  }
  .select2-dropdown {
    border-color:@border_color_3;
  }
}
.select2-container--classic.select2-container--open.select2-container--above {
  .select2-selection--single {
    border-top:none;
    border-top-left-radius:0;
    border-top-right-radius:0;
    background-image:-webkit-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image:     -o-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image:        linear-gradient(to bottom, white 0%, #eeeeee 50%);
    background-repeat:repeat-x;

    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
  }
  .select2-selection--multiple {
    border-top:none;
    border-top-left-radius:0;
    border-top-right-radius:0;
  }
}
.select2-container--classic.select2-container--open.select2-container--below {
  .select2-selection--single {
    border-bottom:none;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
    background-image:-webkit-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image:     -o-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image:        linear-gradient(to bottom, #eeeeee 50%, white 100%);
    background-repeat:repeat-x;

    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
  }
  .select2-selection--multiple {
    border-bottom:none;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
}