@import '_variables';
#properties {
  .item {
    padding-right:0;
  }
}

#add-evaluation-thank{
  .has-bg{
    &::after{
      right: 0;
    }
  }
}