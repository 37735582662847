@import '_variables';
.modal-backdrop.show {
  opacity:.75;
}


.modal {
  .modal-dialog {
    margin-top:79px;
  }

  .modal-content {
    padding:28px 23px;
    border-radius:0;
	border:none;
  }

  h4 {
    margin-bottom:8px;
    font-family:Ubuntu-Light;
    font-size:25px;
    + span {
      font-family:Ubuntu;
      font-size:15px;
    }
  }
  form {
    padding-top:23px;
    .btn-purple {
      margin-top:24px;
      padding:14px 57px 14px 30px;
    }
  }
  .close {
    float:none;
    position:absolute;
    top:-12px;
    right:-1px;
    outline:none;
    opacity:1 !important;
    .a {
      transition:@transition;

      fill:@color-icon-close;
      stroke-width:0;;
    }
    &:focus, &:active, &:hover {
      .a {
        fill:@color-link-hover;
      }
    }
  }
}


label, .label {
  margin-bottom:4px;
  font-family:Ubuntu;
  font-size:15px;
  font-weight:bold;
}
.form-control {
  height:60px;
  padding:15px;
  border-width:2px;
  border-color:@color-border-form;
  border-radius:0;
  box-shadow:none !important;
  font-family:Ubuntu;
  font-size:15px;

  &:focus, &:active, &:hover {
    border-color:@color-border-form-hover;
  }
  textarea& {
    resize:none;
  }
  &.calendar {
    padding-right:50px;
    background-image:url(img/icon-calendar.svg);
    background-repeat:no-repeat;
    background-position:97% center;
  }
}


.form-group {
  margin-bottom:16px;
}


.btn {
  position:relative;
  padding:9px 24px;
  border-width:2px;
  border-radius:0;
  box-shadow:none !important;
  font-family:Ubuntu;
  font-size:16px;
  font-weight:bold;
  &.no-line {
    &::before {
      display:none !important;
    }
  }
}


.btn-purple {
  position:relative;
  border-color:@color-border-btn;
  color:@color-black;
  background-color:transparent;
  &:focus, &:active, &:hover {
    color:@color-white;
    background-color:@color-border-btn;
  }
  &::before {
    position:absolute;
    top:50%;
    right:-16px;
    width:30px;
    border-top:2px solid @color-black;
    content:'';
    transition:@transition;
  }
}

.btn-light {
  color:@color-white;
  background-color:transparent;
  &::before {
    position:absolute;
    top:50%;
    right:-16px;
    width:30px;
    border-top:2px solid @color-white;
    content:'';
    transition:@transition;
  }
  &:focus, &:active, &:hover {
    border-color:@color-border-btn;
    background-color:@color-border-btn;
  }
}

.btn-black {
  border-color:@color-border-btn;
  color:@color-white;
  background-color:@color-border-btn;
  &::before {
    position:absolute;
    top:50%;
    right:-16px;
    width:30px;
    border-top:2px solid @color-black;
    content:'';
    transition:@transition;
  }
  &:focus, &:active, &:hover {
    border-color:@color-black;
    color:@color-white;
    background-color:@color-black;
    &::before {
      border-color:@color-border-btn;
    }
  }
  &.back {
    border-color:@color-black;
    color:@color-text;
    background-color:transparent;
    &::before {
      border-color:@color-black;
    }
    &:focus, &:active, &:hover {
      border-color:@color-border-btn;
      color:@color-white;
      background-color:@color-border-btn;
    }
  }
}

figure {
  margin:0;
}

blockquote {
  padding:45px 45px 45px 150px;
  color:@color-blockquote;
  background-color:@color-white;
  background-image:url(img/screamer.png);
  background-repeat:no-repeat;
  background-position:45px 45px;
  box-shadow:0 0 30px 0 rgba(186, 186, 186, .35);
  font-size:14px;
}

p {
  &:last-of-type {
    margin-bottom:0;
  }
}

.form-check {
  padding-left:0;
}
input[type='checkbox'] {
  display:none;
  & + label {
    margin-bottom:0;
    cursor:pointer;
    &::before {
      display:inline-block;
      position:static;
      width:30px;
      height:30px;
      margin-right:15px;
      border:2px solid @color-border-form;
      border-radius:0!important;
      background-image:none;
      background-repeat:no-repeat;
      background-position:center;
      vertical-align:middle;
      content:'';
      transition:@transition;
    }
  }

  &:checked {
    & + label {
      &::before {
        border-color:@color-border-form-hover !important;
        background-color:transparent !important;
        background-image:url(img/icon-check-bird.svg);
      }
    }
  }

  &:hover, &:active {
    & + label {
      &::before {
        border-color:@color-border-form-hover;
      }
    }
  }
}

.custom-control {
  padding-left:0;
}

.hint-block {
  min-height:20px;
  margin-top:10px;
  padding:0 25px;
  color:@color-time;
  background-image:url(img/icon-info.svg);
  background-repeat:no-repeat;
  background-position:0 0;
  font-family:Ubuntu-Light;
  font-size:13px;
  line-height:13px;
}

.form-check-inline {
  margin-right:1.5rem;
}

.alert {
  position:absolute;
  top:10px;
  right:20px;
  z-index:10;
  width:315px;
  padding:15px;
  font-size:14px;
  line-height:16px;
}

.alert-dismissible {
  .close {
    top:2px;
    right:5px;
    padding:0;
    outline:none;
    font-size:24px;
    line-height:15px;
  }
}