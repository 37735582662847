.owl-carousel {
  .owl-video-wrapper {
    position:relative;
    height:100%;
    background:#000000;
  }

  .owl-video-play-icon {
    position:absolute;
    top:50%;
    left:50%;
    z-index:1;
    width:80px;
    height:80px;
    margin-top:-40px;
    margin-left:-40px;
    background:url('owl.video.play.png') no-repeat;
    cursor:pointer;
    transition:transform 100ms ease;

    -webkit-backface-visibility:hidden;
  }

  .owl-video-play-icon:hover {
    transform:scale(1.3, 1.3);
  }

  .owl-video-playing .owl-video-tn, .owl-video-playing .owl-video-play-icon {
    display:none;
  }

  .owl-video-tn {
    height:100%;
    opacity:0;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:contain;
    transition:opacity 400ms ease;
  }

  .owl-video-frame {
    position:relative;
    z-index:1;
    width:100%;
    height:100%;
  }
}