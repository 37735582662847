@import '_variables';

svg#logo_big {
  max-width:100%;
}

.rotate-anim {
  &::before {
    transform:rotate(180deg);
  }
}

.back-link {
  color:@color-text;
  font-size:16px;
  font-weight:bold;

  .a {
    transition:@transition;

    fill:@color-text;
    stroke-width:0;
  }

  &:hover, &:active {
    color:@color-link-hover;
    .a {
      fill:@color-link-hover;
    }
  }
}

main {
  ul {
    margin:0;
    padding:0;
    list-style-type:none;
    li {
      margin-bottom:13px;
      padding-left:32px;
      background-image:url(img/icon-check-bird.svg);
      background-repeat:no-repeat;
      background-position:left center;
    }
  }
}

.link-black {
  color:@color-text;
  font-size:16px;
  font-weight:bold;
  &:hover, &:active {
    color:@color-link-hover;
  }
}