@import '_variables';

#main-header{
    .cull-us{
        padding-left: 10px;
    }
    
    .have-question{
        padding-right: 10px;
        padding-left: 10px;
    }
    
    .language-menu{
        padding-right: 10px;
        padding-left: 0;
    }
    
    .main-navbar{
        .navbar-collapse{
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 0;
            background-color: @bg-color-header;
            
            .navbar-nav{
                padding: 10px 20px;
            }
            
            .nav-item{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        
        .account{
            display: block;
            
            .nav-item{
                display: block;
                float: left;
            }
        }
    }
}

#gas-price{
    padding: 50px 30px;
    
    h2{
        font-size: 30px;
    }
    
    img{
        display: none;
    }
    
    .note{
        width: auto;
        margin-top: 0;
    }
}

#properties{
    margin-top: 80px;
    
    .item{
        margin-bottom: 50px;
        padding-right: 0;
    }
}

#loyalty-program{
    font-size: 14px;
    
    h2{
        margin-bottom: 10px;
    }
    
    p{
        line-height: 18px;
    }
    
    .btn-light{
        margin-top: 0;
    }
}

h2, .h2{
    &.module{
        margin-bottom: 0;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
    }
}

#stock{
    margin-top: 100px;
    margin-bottom: 160px;
    
    figcaption{
        padding: 40px 45px 40px 35px;
        
        &[class*=first]{
            padding: 40px 35px;
        }
    }
    
    h2{
        margin-bottom: 20px;
    }
    
    .btn{
        margin-top: 10px;
    }
}

#footer{
    .item-menu{
        margin-bottom: 45px;
    }
    
    .bottom-block{
        margin-top: 23px;
        margin-bottom: 0;
        border: none;
        
        span{
            display: block;
        }
    }
    
    .soc{
        margin-top: 35px;
        text-align: left;
        
        a{
            margin-right: 15px;
            margin-left: 0;
        }
    }
}

.fuel-one-type-text{
    margin-top: 50px;
    margin-bottom: 60px;
    
    .image{
        img{
            width: 50%;
            margin: 0 auto 30px;
        }
    }
    
    figcaption{
        padding-left: 15px;
    }
}

.page-gross-fuel{
    .block-order{
        span{
            display: block;
        }
    }
}

.page-fuel-cards{
    .list-coupons{
        margin-bottom: 0;
        
        .item{
            text-align: center;
        }
        
        img{
            margin-right: auto;
            margin-left: auto;
        }
    }
    
    .cards{
        margin-top: -125px;
    }
}

#top-image h1{
    font-size: 28px;
}

blockquote{
    padding: 30px 30px 30px 100px;
    background-position: 20px 30px;
}

#top-image.club{
    figcaption{
        top: auto;
        bottom: 15px;
        height: auto;
        margin-top: 0;
    }
    
    h1{
        margin-bottom: 0;
        font-size: 36px;
        line-height: 36px;
    }
    
    .block-link{
        margin-top: 15px;
    }
}

#add-evaluation-thank{
    .has-bg{
        &::after{
            right: -225px;
        }
    }
}