/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display:none;
  /* position relative and z-index fix webkit rendering fonts issue */
  position:relative;
  width:100%;

  -webkit-tap-highlight-color:transparent;

  .owl-stage {
    position:relative;

    -moz-backface-visibility:hidden; /* fix firefox animation glitch */
    -ms-touch-action:pan-Y;
        touch-action:manipulation;
  }

  .owl-stage:after {
    display:block;
    visibility:hidden;
    clear:both;
    height:0;
    line-height:0;
    content:'.';
  }

  .owl-stage-outer {
    overflow:hidden;
    position:relative;
    /* fix for flashing background */
    -webkit-transform:translate3d(0px, 0px, 0px);
  }

  .owl-wrapper, .owl-item {
    -webkit-transform:translate3d(0, 0, 0);
       -moz-transform:translate3d(0, 0, 0);
        -ms-transform:translate3d(0, 0, 0);

    -webkit-backface-visibility:hidden;
       -moz-backface-visibility:hidden;
        -ms-backface-visibility:hidden;
  }

  .owl-item {
    float:left;
    position:relative;
    min-height:1px;

    -webkit-backface-visibility:hidden;
    -webkit-tap-highlight-color:transparent;
    -webkit-touch-callout:none;
  }
  .owl-item img {
    display:block;
    width:100%;
  }

  .owl-nav.disabled, .owl-dots.disabled {
    display:none;
  }

  .owl-nav .owl-prev, .owl-nav .owl-next, .owl-dot {
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;

     -khtml-user-select:none;
  }

  button.owl-dot {
    padding:0 !important;
    border:none;
    color:inherit;
    background:none;
    font:inherit;
  }

  .owl-nav button.owl-prev, .owl-nav button.owl-next {
    display:inline-block;
    position:absolute;
    top:50%;
    z-index:15;
    width:50px;
    height:100px;
    margin-top:-50px;
    padding:0 !important;
    border:none;
    outline:none;
    opacity:.502;
    color:inherit;
    background-color:#161616;
    background-repeat:no-repeat;
    background-position:center center;
    font:inherit;
    cursor:pointer;
    transition:.3s;
    &:hover {
      opacity:1;
    }
  }

  .owl-nav button.owl-prev {
    left:0;
    background-image:url(img/icon-arrow-slider-left.svg);
  }
  .owl-nav button.owl-next {
    right:0;
    background-image:url(img/icon-arrow-slider-right.svg);
  }

  &.owl-loaded {
    display:block;
  }

  &.owl-loading {
    display:block;
    opacity:0;
  }

  &.owl-hidden {
    opacity:0;
  }

  &.owl-refresh .owl-item {
    visibility:hidden;
  }

  &.owl-drag .owl-item {
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;

    touch-action:pan-y;
  }

  &.owl-grab {
    cursor:move;
    cursor:grab;
  }

  &.owl-rtl {
    direction:rtl;
  }

  &.owl-rtl .owl-item {
    float:right;
  }
}

/* No Js */
.no-js .owl-carousel {
  display:block;
}