@import '_variables';

@font-face{
    font-family: 'Montserrat-Black';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Montserrat-Black.woff') format('woff'),
    url('fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face{
    font-family: 'Montserrat-ExtraBold';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Montserrat-ExtraBold.woff') format('woff'),
    url('fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Ubuntu-Regular.woff') format('woff'),
    url('fonts/Ubuntu-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu';
    font-weight: bold;
    font-style: normal;
    src: url('fonts/Ubuntu-Bold.woff') format('woff'),
    url('fonts/Ubuntu-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu';
    font-weight: normal;
    font-style: italic;
    src: url('fonts/Ubuntu-Italic.woff') format('woff'),
    url('fonts/Ubuntu-Italic.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu';
    font-weight: bold;
    font-style: italic;
    src: url('fonts/Ubuntu-BoldItalic.woff') format('woff'),
    url('fonts/Ubuntu-BoldItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu-Light';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Ubuntu-Light.woff') format('woff'),
    url('fonts/Ubuntu-Light.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu-Light';
    font-weight: normal;
    font-style: italic;
    src: url('fonts/Ubuntu-LightItalic.woff') format('woff'),
    url('fonts/Ubuntu-LightItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu-Medium';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Ubuntu-Medium.woff') format('woff'),
    url('fonts/Ubuntu-Medium.ttf') format('truetype');
}

@font-face{
    font-family: 'Ubuntu-Medium';
    font-weight: normal;
    font-style: italic;
    src: url('fonts/Ubuntu-MediumItalic.woff') format('woff'),
    url('fonts/Ubuntu-MediumItalic.ttf') format('truetype');
}

*{
    outline: none;
}

html, body{
    min-width: 345px;
    max-width: 100%;
    margin: 0 auto;
}

body{
    position: relative;
    color: @color-text;
    background-color: @bg-color-body;
    font-family: Ubuntu;
    font-size: 15px;
}

a{
    transition: @transition;
    
    &:hover, &:active{
        color: @color-link-hover;
        text-decoration: none;
    }
}

h1, .h1{
    font-family: Ubuntu;
    font-size: 35px;
    font-weight: bold;
    
    &::before{
        display: inline-block;
        width: 40px;
        margin-right: 15px;
        border-bottom: 1px solid @color-white;
        vertical-align: middle;
        content: '';
        transition: @transition;
        transform: rotate(0);
    }
}

h2, .h2{
    margin-bottom: 33px;
    font-family: Ubuntu;
    font-size: 22px;
    font-weight: bold;
    line-height: 18px;
    
    &.module{
        margin-bottom: 0;
        font-family: Montserrat-ExtraBold;
        font-size: 30px;
        line-height: 30px;
        text-transform: uppercase;
        
        & + .title{
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
        }
    }
}

h3, .h3{
    font-size: 20px;
    font-weight: bold;
}

h4, .h4{
}

h5, .h5{
}

time{
    color: @color-link-hover;
}

p{
    line-height: 1.5rem;
}